import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Span } from 'components/'
import DigiPadalaSuki from 'images/digipadalasuki.png'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/DigiPadalaSuki`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'DIGIPADALA SUKI RAFFLE DRAW (November 1-30, 2020)',
  },
  {
    property: 'og:description',
    content:
      'Sali na at maging isa sa 10 lucky winners of P1,000 kapag nag-send money ka sa DigiPadala! Ang 1 pera padala with a minimum amount of P1,000 ay katumbas ng 1 raffle entry.',
  },
  {
    property: 'og:image',
    content: DigiPadalaSuki,
  },
]

const Promo4 = () => (
  <div>
    <Helmet meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <Image src={DigiPadalaSuki} width="100%" align="center" />
    <div className="promo-page">
      <h4>PAANO SUMALI</h4>
      <ul>
        <li>Ang ‘DIGIPADALA SUKI’ ay mula November 1-30, 2020 lang.</li>
        <li>
          Bukas ito para sa mga Pilipino na may edad 18 taong gulang pataas.
        </li>
        <li>
          Para mag-qualify, mag-send ng pera via DigiPadala na may minimum
          amount na P1,000.
          <ul>
            <li>
              DigiPadala Branch Locator:{' '}
              <a
                className="branch-locator-link"
                href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/branch-locator/`}
              >
                https://www.digipay.ph/branch-locator/
              </a>
            </li>
          </ul>
        </li>
        <li>
          Ang isang (1) send money ay katumbas ng isang (1) raffle entry.
          Maaaring magkaroon ng higit sa isang entry.
          <ul>
            <li>
              Halimbawa: Dalawang (2) beses ka nagpadala ng pera via DigiPadala.
              Ito ay katumbas ng dalawang (2) raffle entries.
            </li>
          </ul>
        </li>
        <li>Mas maraming entries, mas malaki ang chance mong manalo.</li>
        <li>Sampung (10) winners ang mananalo ng tig-P1,000.</li>
        <li>
          Ang mga winners ay pipiliin sa pamamagitan ng electronic raffle draw
          at ia-announce sa December 2, 2020.
        </li>
        <li>Isang beses lang maaaring manalo.</li>
      </ul>
      <hr />
      <h4>PAANO MAKUHA ANG PREMYO</h4>
      <ul>
        <li>Ang mga winners ay tatawagan ng Digipay.</li>
        <li>
          Kailangang mag-send ng winner ng isang (1) valid ID para sa
          verification.
        </li>
        <li>
          Maaaring i-claim ng winners ang kanilang premyo sa pamamagitan ng
          DigiPadala sa mga authorized Digipay Agents.
        </li>
        <li>
          Ang crediting ng prizes ay maaaring tumagal ng five (5) business days
          pagkatapos mai-announce ang winners.
        </li>
      </ul>
      <hr />
      <h4>TERMS AND CONDITIONS</h4>
      <ol>
        <li>
          Ang promoter ay FSG Technology Ventures Inc. (DIGIPAY), na may
          registered office address sa 6F, G Business Hub, 728 Shaw Boulevard,
          Mandaluyong City 1552.
        </li>
        <li>
          Ang promotion ay bukas para sa mga residente ng Pilipinas na may edad
          18 taong gulang pataas.
        </li>
        <li>Kailangang mag-send ng pera sa DigiPadala para makasali.</li>
        <li>
          Maaaring magkaroon ng higit sa isang entry. Mas maraming entries, mas
          malaki ang chance mong manalo.
        </li>
        <li>
          Ang tuntunin ng promotion na ito ay ang mga sumusunod:
          <ul>
            <li>
              Mag-send ng pera via Digipadala. Find a store here:{' '}
              <a
                className="branch-locator-link"
                href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/branch-locator/`}
              >
                https://www.digipay.ph/branch-locator/
              </a>
            </li>
            <li>
              Para mag-qualify, mag-send ng pera via DigiPadala na may minimum
              amount na P1,000. Ang DigiPadala Suki ay makakakuha ng raffle
              entries na katumbas kung ilang beses siya nagpadala.
            </li>
            <li>
              Ang isang (1) send money ay katumbas ng isang (1) raffle entry.
              Maaaring magkaroon ng higit sa isang entry.
            </li>
            <li>
              Ang mga winners ay pipiliin sa pamamagitan ng electronic raffle
              draw at ia-announce sa December 2, 2020 na gaganapin sa opisina ng
              DIGIPAY.
            </li>
            <li>Ang DigiPadala Suki ay isang beses lang maaaring manalo.</li>
          </ul>
        </li>
        <li>Hindi maaaring ipasa ang premyo.</li>
        <li>
          Maaaring i-claim ng winners ang kanilang premyo sa pamamagitan ng
          DigiPadala sa mga authorized Digipay Agents.
        </li>
        <li>
          Pumapayag ang winner na gamitin ang kanyang pangalan at larawan sa ano
          mang publicity material. Ano mang personal na impormasyon tungkol sa
          nanalo o mga sumali ay gagamitin lamang nang naaayon sa Philippine
          data protection legislation at hindi ibibigay sa ano mang institusyon
          ng walang pahintulot.
        </li>
        <li>
          Sa pagsali sa promotion na ito, pumapayag ang entrant sa mga
          nakapaloob na terms and conditions.{' '}
        </li>
      </ol>
    </div>
    <Footer />
  </div>
)
export default Promo4
